
export class ModelDevice{
    id: number = 0;
    uid: string = '';
    country: string | null = '';
    region: string |  null = '';
    city: string | null = '';
    name: string | null = '';
    comment: string | null = '';
    psswbt: string | null = '';
    btname: string | null = '';
    class: string | null = '';
    source: string | null = '';
    tamper: string | null = '';
    tension: string | null = '';
    status: string | null = 'INACTIVO';
    frecuencia_lectura: number = 0;
    frecuencia_lectura_device: number = 0;
    frecuencia_reporte: number = 0;
    frecuencia_reporte_device: number = 0;
    isAsc: boolean = false;
}
